import _maxBy from 'lodash/maxBy';
import { USER_ROLES, USER_ROLES_WEIGHT } from 'src/utils/constants';

export const getUserLocations = (locations, roles) => {
  // Get the highest role of teh current user
  const userRoleMax = _maxBy(roles, userRoles => USER_ROLES_WEIGHT[userRoles.role]);
  // Decide which locations to show based on the user's role
  let locationsFiltered = locations;
  // If the user highest role is manager -> no restrictions, otherwise:
  if (userRoleMax?.role !== USER_ROLES.MANAGER) {
    // The user is not a manager so we should only show the locations they have access to
    // (get all location roles uris)
    const allowedLocations = new Set(roles.map(role => role.location).filter(Boolean));
    // Filter locations by allowed locations
    locationsFiltered = locations.filter(location => allowedLocations.has(location.uri));
  }

  // Return filtered locations
  return locationsFiltered;
};
